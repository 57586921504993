import { createContext, useEffect, useState } from "react";
import { SdfButton } from "@synerg/react-components";

import "./TopButton.css";

/**
 * @type React.Context<{
 *  offsetHeight: number,
 *  setOffsetHeight: (height: number) => void,
 * }>
 */
const TopButtonContext = createContext(null);

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

/**
 * @param {{ children: React.ReactNode }} props
 */
const TopButton = ({ children }) => {
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => setIsAtTop(window.scrollY === 0);
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <TopButtonContext.Provider value={{ offsetHeight, setOffsetHeight }}>
      {children}
      {!isAtTop && (
        <SdfButton
          emphasis="primary"
          iconOnly={true}
          icon="nav-move-up"
          onClick={scrollToTop}
          className="fixed m-4 right-0 top-button"
          style={{ "--top-button-bottom": `${offsetHeight}px` }}
          aria-label="Scroll to top"
        />
      )}
    </TopButtonContext.Provider>
  );
};

export default TopButton;
export { TopButtonContext };
