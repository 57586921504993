import React from "react";
import { SdfExpandableBox } from "@synerg/react-components";

const FAQBox = ({ header, detail }) => {
  return (
    <SdfExpandableBox
      className="h-16 m-1 text-sm"
      header={header}
      triggerPlacement="after"
      variant="shadowed"
      spacing="tight"
      title={header}
      headingLevel={3}
    >
      <div className="mt-4 body-02 text-body-02 p-2">
        {<p dangerouslySetInnerHTML={{ __html: detail }} />}
      </div>
    </SdfExpandableBox>
  );
};

export function FAQs({ faqItems }) {
  return (
    <div className="flex flex-col m-auto py-6 max-w-3/4">
      <h2 className="text-heading-06 font-black text-center pb-4">
        Commonly asked questions
      </h2>
      <ul className="pb-12 list-none">
        {faqItems.map((item, idx) => (
          <li key={idx}>
            <FAQBox key={idx} {...item} />
          </li>
        ))}
      </ul>
    </div>
  );
}
