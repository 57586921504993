import { useEffect, useState } from "react";

import HeroImage from "../shared/HeroImage";
import { HeroImageList } from "../shared/HeroImageList";
import image from "../../assets/images/hero_desktop.png";
import "./Header.css";

const imageProps = {
  src: image,
  alt: "A woman smiling as she sits at her desk and views her laptop",
  partnerLanding: false,
  linkList: [
    {
      title: "Recruiting",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=staffing",
      className: "hero-image-recruiting",
    },
    {
      title: "Benefits",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=benefits",
      className: "hero-image-benefits",
    },
    {
      title: "Onboarding",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=hcm",
      className: "hero-image-onboarding",
    },
    {
      title: "Payroll",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=payroll",
      className: "hero-image-payroll",
    },
    {
      title: "Employee data",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=hr",
      className: "hero-image-data",
    },
    {
      title: "Time & attendance",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=time",
      className: "hero-image-time",
    },
    {
      title: "Employee qualifications",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=talent",
      className: "hero-image-qualifications",
    },
  ],
};

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="w-11/12 md:w-4/5 pb-12">
      <div className="flex flex-col xl:flex-row">
        <div className="xl:mr-16 my-auto">
          <h1 className="m-0 pb-8 text-heading-08">
            Integrate your HR and business solutions with ADP APIs
          </h1>
          <p className="m-0 text-heading-04">
            ADP® API Central provides instant access to APIs, tools and
            services, all in one place.
          </p>
        </div>
        {windowWidth >= 640 ? (
          <div className="flex-shrink-0 h-xs w-full pt-16 xl:w-xl xl:py-0">
            <HeroImage {...imageProps} />
          </div>
        ) : (
          <div className="pt-16">
            <HeroImageList {...imageProps} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
