import React, { useState, useRef, useEffect } from "react";
import { SdfTab, SdfTabGroup, SdfLink } from "@synerg/react-components";
import "./style.css";
import headerImg from "../../assets/images/new-graphic-v2.png";
import IntegrationSteps from "./IntegrationSteps";
import { TIMELINE_1_ITEMS, TIMELINE_2_ITEMS } from "./utility/constants";
import { useNavigate } from "react-router-dom";
import Timeline from "../shared/Timeline";

export function UseCases({ useCaseRoles }) {
  const [selectedTab, setSelectedTab] = useState(0);
  let navigate = useNavigate();

  function onSelectedTab(event) {
    event.detail.value === 0 ? setSelectedTab(0) : setSelectedTab(1);
  }

  function navigateGuide() {
    selectedTab === 0
      ? navigate("/getting-started/client-integration-overview")
      : navigate("/getting-started/partner-integration-overview");
  }

  const timelineRef = useRef(null);
  const [, setTimelineWidth] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      setTimelineWidth(entry.contentRect.width);
    });

    if (timelineRef.current) {
      resizeObserver.observe(timelineRef.current);
    }

    return () => {
      if (timelineRef.current) {
        resizeObserver.unobserve(timelineRef.current);
      }
    };
  }, [timelineRef.current]);

  return (
    <div className="m-auto mt-16 items-center flex flex-col">
      <div className="w-3/5 bg-action-primary-900 rounded-lg py-8 px-12 flex flex-col lg:flex-row justify-between gap-6">
        <div className="pl-4 xl:w-1/2">
          <h2 className="font-black text-inverse-default xl:text-heading-06 lg:text-heading-05 text-heading-05 max-w-full m-0">
            Looking to integrate with your company’s data? Try <br /> ADP API
            Central
          </h2>
          <p className="text-inverse-default mb-4 sm:mb-6 font-medium text-heading-03 max-w-full lg:max-w-4/5">
            API Central makes it easy to access ADP APIs, tools and services,
            all in one place.
          </p>
          <SdfLink
            id="demo-component"
            emphasis="primary"
            title="Learn about APIC"
            size="sm"
            onClick={(event) => {
              event.preventDefault();
              navigate("/getting-started/client-integration-overview");
            }}
          >
            Learn more about API Central
          </SdfLink>
        </div>
        <div className="flex xl:h-12 xl:w-xs sm:w-sm flex-shrink-1 pt-4 justify-center items-center">
          <img src={headerImg} alt="" className="w-11/12" />
        </div>
      </div>

      <div className="flex flex-col m-auto w-4/5 mt-16 items-center text-center">
        <h2 className="font-black text-heading-06">
          What to expect when integrating with ADP
        </h2>
        <p className="p-2 text-center xl:w-3/4 custom-weight text-md leading-md">
          The integration process varies depending on whether you’re an ADP
          client looking to access your own company’s data or an ADP Marketplace
          partner selling your integration to clients on our digital storefront.
        </p>

        <div className="flex items-center justify-center h-full p-8 w-4/5">
          <SdfTabGroup className="w-md" onSdfChange={($event) => onSelectedTab($event)}>
            {useCaseRoles.map((tab, idx) => (
              <SdfTab
                value={idx}
                key={idx}
                active={selectedTab === idx}
                size="large"
                title={tab.label}
                
              >
                {tab.label}
              </SdfTab>
            ))}
          </SdfTabGroup>
        </div>

        <div className="flex flex-col items-center">
          <h3 className="font-bold text-heading-05 text-center m-0 p-0">
            {selectedTab === 0
              ? "Integrating as an ADP client"
              : "Integrating as an ADP Marketplace partner"}
          </h3>
          <div className="w-11/12">
            <ul className="list-none">
              <li>
                {selectedTab === 0 ? (
                  <Timeline
                    timelineItems={TIMELINE_1_ITEMS}
                    hideDetails={true}
                    timelineRef={timelineRef}
                    homePageTimeline={false}
                    cursor={false}
                  />
                ) : (
                  <IntegrationSteps
                    timeline2Items={TIMELINE_2_ITEMS}
                    timelineRef={timelineRef}
                  />
                )}
              </li>
            </ul>
          </div>
        </div>

        <SdfLink
          href="#"
          id={selectedTab === 0 ? "getStarted1" : "getStarted2"}
          emphasis="primary"
          title="Learn more"
          className="pt-12"
          onClick={() => navigateGuide()}
        >
          {selectedTab === 0
            ? "Learn what’s available to ADP clients"
            : "Learn what’s available to ADP Marketplace partners"}
        </SdfLink>
      </div>
    </div>
  );
}
