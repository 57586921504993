import React from "react";
import { PartnerSection } from "../components/partner-integration/PartnerSection";
import { MarketplaceSection } from "../components/partner-integration/MarketplaceSection";
import { ClientSection } from "../components/partner-integration/ClientSection";
import { TIMELINE_2_ITEMS } from "../components/welcome-page/utility/constants";
import AvailableGuides from "../components/ClientIntegration/AvailableGuides";
import {
  FAQ_ITEMS,
  GUIDE_ITEM_LIST,
} from "../components/partner-integration/utility/constants";
import { FAQs } from "../components/shared/FAQs";
import Breadcrumb from "../components/Breadcrumb";
import PageLoading from "../components/shared/PageLoading";

export function PartnerIntegrationPage() {
  const breadcrumbPath = [
    { name: "Home", route: "/" },
    {
      name: "Getting Started / Partner Integration Overview",
      route: "/getting-started/partner-integration-overview",
    },
  ];
  return (
    <PageLoading>
      <div className="pl-10 md:pr-0 mr-20">
        <Breadcrumb path={breadcrumbPath} />
      </div>

      <div className=" items-center flex flex-col">
        <MarketplaceSection />
        <ClientSection />
        <PartnerSection timeline={TIMELINE_2_ITEMS} />
        <div className="overflow-hidden flex flex-col items-center py-20">
          <AvailableGuides guideItemList={GUIDE_ITEM_LIST} />
        </div>
        <div className="min-w-full bg-action-default-50 relative">
          <FAQs faqItems={FAQ_ITEMS} />
        </div>
      </div>
    </PageLoading>
  );
}
